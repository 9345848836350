<template>
  <static-fullscreen-card>
    <template v-slot:header> </template>
    <template v-slot:title>
      <v-row class="ma-1">
        <v-select outlined :items="cashList" item-text="text" item-value="value" v-model="cashbox" label="Касса" hide-details dense class="mt-0" />
        <v-checkbox v-model="showPeriod" label="по периодам" dense hide-details></v-checkbox>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn-toggle v-if="!showPeriod" v-model="period" mandatory borderless dense class="ml-1" @click="onChangePeriod">
        <v-btn value="1W" @click="onChangePeriod('1W')">
          <span class1="hidden-md-and-down">Н</span>
        </v-btn>
        <v-btn value="1M" class="mx-1" @click="onChangePeriod('1M')">
          <span class1="hidden-md-and-down">1М</span>
        </v-btn>
        <v-btn value="6M" class="" @click="onChangePeriod('6M')">
          <span class1="hidden-md-and-down">6М</span>
        </v-btn>
        <v-btn value="S" class="" @click="showConfig = true">
          <v-icon> fas fa-cog </v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-btn v-if="showPeriod && cashPeriod.length < cashReminders.length" color="primary" @click="showCashPeriod(-1)"
        ><btn-title icon1="fas fa-sync-a">еще</btn-title>
      </v-btn>
      <v-btn color="primary" @click="onRefresh()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main"> </portal>
    <a-table-f-sql
      v-if="!showPeriod"
      ref="table"
      :query="sql"
      :model="model"
      :useQuery="false"
      :defaults="{ paramName: 'accCashBox', sort: {} }"
      @click="onClickRow($event)"
      @loadData="onUpdateData()"
    >
      <template v-slot:footer>
        <slot name="footer">
          <div class="f-table-footer">
            <div class="f-table-row">
              <div style="margin: 6px 6px">{{ footerText }}</div>
            </div>
          </div>
        </slot>
      </template>
    </a-table-f-sql>
    <a-table-f-data2
      v-if="showPeriod"
      ref="table"
      :dataTable="cashPeriod"
      :useQuery="false"
      :model="modelPeriod"
      :searchable="false"
      @click="onClickPeriodRow($event)"
      :defaults="{
        filters: {},
        sort: { key: 'id', order: 'DESC' },
        paramName: 'accCashBoxPeriod',
      }"
    >
    </a-table-f-data2>
    <s-quick-config-dialog v-if="showConfig" v-model="showConfig" :values="{ dateStart, dateEnd }" :model="modelConfig" @apply="onConfig($event)" />
    <CashDialog ref="cashDialog" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect } from "@/components/mixings";
import libsCash from "@/views/documents/libsCash";
export default {
  mixins: [getAccess, libsCash, keyDetect],
  components: {
    CashDialog: () => import("@/views/documents/dialogs/cashDialog"),
  },
  data() {
    return {
      idDoc: 0,
      idStore: 0,
      dateStart: new Date().addDays(-7).date,
      dateEnd: new Date().date,
      cashType: null,
      showConfig: false,
      showEditDialog: false,
      showPeriod: false,
      title: "",
      defaults: {
        paramName: "accCashBox",
        sort: {},
        pager: {
          page: 0,
          count: 0,
          limit: 10,
        },
      },
      cashList: null,
      cashbox: -1,
      modelConfig: [
        { name: "dateStart", title: "начало", type: "datestring" },
        { name: "dateEnd", title: "конец", type: "datestring" },
      ],
      total: { count: null, in: null, out: null, begin: null },
      balanceSql: "",
      period: "1W",
      cashPeriod: [],
      cashReminders: [],
      modelPeriod: [
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        { name: "cashbox_id", title: "Касса", type: "select", dir: "cashbox", width: 250, style: "white-space: normal;" },
        { name: "date_start", title: "Дата начала", type: "datetime", sortable: false, width: 120 },
        { name: "value_start", title: "Остаток ", type: "number", sortable: false, width: 100 },
        { name: "value_in", title: "Приход", type: "number", sortable: false, width: 100 },
        { name: "value_out", title: "Расход", type: "number", sortable: false, width: 100 },
        { name: "date_end", title: "Дата конца", type: "datetime", sortable: false, width: 120 },
        { name: "value_end", title: "Остаток", type: "number", sortable: false, width: 100 },
        { name: "divergence", title: "Расхождение", type: "number", sortable: false, width: 100 },
      ],
    };
  },
  created() {
    this.$root.title = `Журнал "Касса" с ${this.dateStart} по ${this.dateEnd}`;
    this.getCashList();
  },
  computed: {
    footerText() {
      let text = "";
      text = `Показано: ${this.total.count}; На начало: ${this.total.begin};   Приход: ${this.total.in};   Расход: ${this.total.out};`;
      return text;
    },
    model() {
      let model = [
        { name: "code_doc", title: "Номер", type: "string", sortable: true, width: 50 },
        { name: "date_doc", title: "Дата", type: "datetime", sortable: true, width: 150 },
        { name: "operation_type", title: "Операция", type: "select", dir: "operationTable", sortable: true, width: 200 },
        { name: "cashbox_id", title: "Касса", type: "select", dir: "cashbox", sortable: true, width: 150 },
        { name: "val_in", title: "Сумма+", type: "number", sortable: true, width: 100 },
        { name: "val_out", title: "Сумма-", type: "number", sortable: true, width: 100 },
        {
          name: "status",
          title: "status",
          type: "select",
          options: [
            { value: 1, text: "Не проведен" },
            { value: 2, text: "Проведен" },
          ],
        },
      ];
      return model;
    },
    sql() {
      let operations = this.$root.dirs.operationTable.filter(o => {
        if ((o.debit || "").includes("50.") || (o.credit || "").includes("50.")) return true;
        return false;
      });
      let list = operations
        .map(o => {
          return o.id;
        })
        .join();
      let cashbox = "";
      let group = "";
      let order = "date_doc desc";
      let where = "";
      let footer = [
        { name: "val_in", title: "Приход" },
        { name: "val_out", title: "Расход" },
      ];
      if (this.cashbox > 0) cashbox = ` and cashbox_id = ${this.cashbox} `;
      let sql = `
select * from (
SELECT id,code_doc, date_doc, operation_type, cashbox_id, value as val_in,  null as val_out, status,
'AccountingDocOrderReceiptModel'  base_name
from accounting_doc_order_receipt where operation_type in(${list}) ${cashbox}
and date_doc >= '${this.dateStart}' and date_doc < DATE_ADD('${this.dateEnd}', INTERVAL 1 DAY) and status=2
union all
/* SELECT id,code_doc, date_doc, operation_type, second_cashbox_id as cashbox_id,  value as val_in, null as val_out, status,
'AccountingDocOrderWithdrawalModel'  base_name
from accounting_doc_order_withdrawal where operation_type in(${list})
and second_cashbox_id is not null and second_cashbox_id=${this.cashbox ? this.cashbox : -1}
and date_doc >= '${this.dateStart}' and date_doc < DATE_ADD('${this.dateEnd}', INTERVAL 1 DAY) and status=2
union all */
SELECT id,code_doc, date_doc, operation_type, cashbox_id,  null as val_in, value as val_out, status,
'AccountingDocOrderWithdrawalModel'  base_name
from accounting_doc_order_withdrawal where operation_type in(${list}) ${cashbox}
and date_doc >= '${this.dateStart}' and date_doc < DATE_ADD('${this.dateEnd}', INTERVAL 1 DAY) and status=2
) doc_table
`;
      this.balanceSql = `
select sum(value) as value from (
SELECT id, date_doc, cashbox_id, value
from accounting_doc_order_receipt where operation_type in(${list}) ${cashbox}
and date_doc < '${this.dateStart}' and status=2
/*
union all
SELECT id, date_doc, second_cashbox_id as cashbox_id, value
from accounting_doc_order_withdrawal
where operation_type in(${list})
  and second_cashbox_id is not null and second_cashbox_id=${this.cashbox ? this.cashbox : -1}
  and date_doc < '${this.dateStart}'  and status=2
*/
union all
SELECT id, date_doc, cashbox_id, -value
from accounting_doc_order_withdrawal
where operation_type in(${list}) ${cashbox}
  and date_doc < '${this.dateStart}'  and status=2
) doc_table
`;
      let res = { sql, order, group, where, footer };
      return res;
    },
  },
  watch: {
    cashbox() {
      this.cashPeriod = [];
      this.getCashPeriod();
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        // this.$refs.table.updateData();
      }
    },
    showPeriod(v) {
      if (v) {
        this.getCashPeriod();
        this.$root.title = `Журнал "Касса" по периодам фиксированных остатков`;
      } else {
        this.$root.title = `Журнал "Касса" с ${this.dateStart} по ${this.dateEnd}`;
        this.cashPeriod = [];
      }
    },
  },
  methods: {
    async onChangePeriod(e) {
      if (e == "6M") {
        this.dateStart = new Date().addDays(-180).date;
        this.dateEnd = new Date().date;
      }
      if (e == "1W") {
        this.dateStart = new Date().addDays(-7).date;
        this.dateEnd = new Date().date;
      }
      if (e == "1M") {
        this.dateStart = new Date().addDays(-30).date;
        this.dateEnd = new Date().date;
      }
      this.$root.title = `Журнал "Касса" с ${this.dateStart} по ${this.dateEnd}`;
      //  this.fetchData();

      //this.$refs.table.updateData();
    },
    onRefresh() {
      if (this.showPeriod) this.getCashPeriod();
      else this.$refs.table.updateData();
    },
    async showCashPeriod(i = -1) {
      if (i == -1) i = this.cashPeriod.length;
      const cash = this.cashbox;
      let p = this.cashReminders;
      let id = this.cashPeriod.length;
      let edate = p[p.length - i]?.date_doc || null;
      let sdate = p[p.length - i - 1]?.date_doc || null;
      let val = p[p.length - 1 - i].amount;
      let resp = await this.$axios.post("/report_sql", { name: "getCashPeriodCheck", params: { cash, edate, sdate } });
      let data = this.cashPeriod;
      if (resp.data.status == "ok") {
        let c = resp.data.data[0];
        id++;
        let r = {
          id,
          cashbox_id: cash,
          date_start: sdate,
          value_start: val,
          value_in: c.val_in,
          value_out: c.val_out,
          date_end: edate,
          value_end: p[p.length - i]?.amount,
        };
        r.divergence = -(r.value_start + r.value_in - r.value_out - r.value_end);
        if (i == 0) r.value_end = r.value_start + r.value_in - r.value_out;

        data = [...data, r];
        this.cashPeriod = data;
      }
    },
    async getCashPeriod() {
      this.cashPeriod = [];
      if (!this.showPeriod) return;
      this.$refs.table.loading = true;
      let data = [];
      let cashList = this.cashList.filter(l => {
        return (l.value == this.cashbox || this.cashbox == -1) && l.value > 0;
      });
      let id = 0;
      if (cashList.length > 1) return;
      for (let ind = 0; ind < cashList.length; ind++) {
        this.$refs.table.loading = true;
        const cash = cashList[ind].value;
        let resp = await this.$axios.post("/report_sql", { name: "getCashPeriod", params: { cash } });
        if (resp.data.status == "ok") {
          let sdate = null;
          let edate = null;
          let val = null;
          let p = resp.data.data;
          this.cashReminders = null;
          this.cashReminders = [...p];
          for (let i = 0; i < p.length && i < 10; i++) {
            resp = null;
            sdate = p[p.length - 1 - i].date_doc;
            val = p[p.length - 1 - i].amount;
            await this.showCashPeriod(i);
            if (false) {
              resp = await this.$axios.post("/report_sql", { name: "getCashPeriodCheck", params: { cash, edate, sdate } });
              if (resp.data.status == "ok") {
                let c = resp.data.data[0];
                id++;
                let r = {
                  id,
                  cashbox_id: cash,
                  date_start: sdate,
                  value_start: val,
                  value_in: c.val_in,
                  value_out: c.val_out,
                  date_end: edate,
                  value_end: p[p.length - i]?.amount,
                };
                r.divergence = -(r.value_start + r.value_in - r.value_out - r.value_end);
                this.cashPeriod = [...this.cashPeriod, r];
                data.push(r);
              }
              edate = p[p.length - 1 - i].date_doc;
            }
          }
          if (false) {
            resp = await this.$axios.post("/report_sql", { name: "getCashPeriodCheck", params: { cash, edate, sdate: null } });
            if (resp.data.status == "ok") {
              let c = resp.data.data[0];
              id++;
              let r = { id, cashbox_id: cash, date_start: sdate, value_start: val, value_in: c.val_in, value_out: c.val_out, date_end: null, value_end: null };
              r.value_end = r.value_start + r.value_in - r.value_out;
              this.cashPeriod = [...this.cashPeriod, r];
              data.push(r);
            }
          }
        }
      }
      this.$refs.table.loading = !true;
    },
    onConfig(e) {
      this.dateStart = e.dateStart;
      this.dateEnd = e.dateEnd;
      this.period = "S";
      this.$root.title = `Журнал "Касса" с ${this.dateStart} по ${this.dateEnd}`;
    },
    onUpdateData() {
      if (this.$refs.table && this.$refs.table.pager.page == 0) {
        console.log("calc footer");
        this.calcBalance();
        this.total.count = this.$refs.table.pager.count;

        this.total.in = (this.$refs.table.pager.val_in || 0).toLocaleString("ru-Ru");
        this.total.out = (this.$refs.table.pager.val_out || 0).toLocaleString("ru-Ru");
      }
    },
    async onClickPeriodRow(d) {
      console.log(d);
    },
    async onClickRow(d) {
      this.$refs.cashDialog.openDialog(this.keys?.ctrl ? -1 : d.row.operation_type, d.row.id, this.data);
    },

    async getCashList() {
      const resp = await this.$axios.get("accounting/dir/cashbox/select");
      this.cashList = [{ value: -1, text: "Все кассы" }, ...resp.data.data];
    },
    async calcBalance() {
      if (!this.balanceSql) return;
      let resp = await this.$axios.post("/accounting/records/report-sql", {
        q: this.balanceSql,
      });
      this.total.begin = (resp.data.data[0].value || 0).toLocaleString("ru-Ru");
    },
  },
};
</script>
